.notification-badge {
  border-radius: 999%;
  background-color: var(--dark-red);
  display: flex;
  aspect-ratio: 1 / 1;
  justify-content: center;
  align-items: center;
}

.notification-badge.nav {
  height: 20px;
  font-size: 12px;
  position: absolute;
  top: 5px;
  left: 0px;
  color: white;
}

.notification-badge.message {
  height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 600;
}