.user-icon {
  aspect-ratio: 1 / 1;
  border-radius: 999%;
  border: 1px solid gray;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: gray;
  box-shadow: 3px 3px 6px black;
}

.user-icon.banner {
  height: 100px;
}

.user-icon.banner span {
  font-size: 40px;
}

.user-icon.nav {
  height: 40px;
  display: inline-block;
  box-shadow: none;
  border: none;
}

.user-icon.nav span {
  font-size: 20px;
}

.user-icon.link {
  height: 90%;
  display: inline-block;
}

.user-icon.link {
  font-size: 30px;
}

.user-icon.messages {
  height: 100px;
}

.user-icon img {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
}

.user-icon span {
  font-weight: 500;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}