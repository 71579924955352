#home {
  grid-column: 1 / -1;
  overflow-y: scroll;
}

#home section {
  height: 100%;
}

#home section img {
  object-fit: contain;
}

#hero {
  position: relative;
  display: flex;
}

#hero img {
  display: inline-block;
  flex: 1;
}

#hero div {
  display: inline-block;
  margin-right: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#hero h1 {
  font-size: 60px;
  text-align: end;
  color: var(--dark-red);
}

#hero p {
  font-size: 25px;
  word-wrap: normal;
  color: #f25787;
}

#profile {
  display: flex;

  position: relative;
}

#profile div {
  margin-left: 50px;
}

#profile h1 {
  font-size: 60px;
}

#profile p {
  font-size: 30px;
  color: gray;
}

#profile img {
  height: 75%;
  width: 75%;
  margin: auto auto;
}

#chat {
  display: flex;
  justify-content: space-between;
}

#chat div {
  margin-right: 50px;
}

#chat p {
  color: gray;
  font-size: 30px;
}

#chat h1 {
  font-size: 60px;
  text-align: end;
}

#chat a {
  text-decoration: none;
  color: white;
}

#chat button {
  display: block;
  margin: auto auto;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: white;
  background-color: var(--accent-two);
  padding: 15px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 10px gray;

  transition: box-shadow 0.5s ease;
}

#chat button:hover {
  box-shadow: 0px 0px 20px black;
}

#chat img {
  width: 75%;
  aspect-ratio: 4 / 3;
}

@media (max-width: 1120px) {
  #home {
    overflow-x: hidden;
    padding: 0 10px;
    padding-bottom: 100px;
  }
  #hero div {
    position: static;
  }
  #home section{
    display: flex;
    flex-direction: column;
  } 
  section:not(section#hero) img {
    order: 2;
    height: 50%;
    width: auto;
  }
  section:not(section#hero) {
    font-size: 20px;
  }
}

@media (max-width: 650px) {
  #home section div h1 {
    font-size: 30px;
  }
  #home section div p {
    font-size: 15px;
  }
}