#sidebar {
  position: absolute;
  right: 0;

  width: 100%;
  height: 100%;

  max-width: 650px;

  z-index: 10;

  background-color: var(--main-bg-color-solid);

  opacity: 1;

  max-width: 0%;

  overflow-y: scroll;

  box-sizing: content-box;

  box-shadow: 0px 0px 3px black;
  border-top-left-radius: 10px;

  transition: max-width 0.75s ease;

}

#sidebar.active {
  max-width: 650px;
  margin-left: 1rem;
} 
