#status-message {
  border-radius: 10px;
}

#status-message.success {
  background-color: #83f783;
  color: green;
}

#status-message p {
  font-size: 14px;
  text-align: center;
}

#status-message.rejected {
  background-color: #f76262;
  color: red;
}

#spinner-container {
  display: flex;
  justify-content: center;
}

#spinner-container i {
  font-size: 28px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } 
  to {
    transform: rotate(360deg);
  }
}
