.input {
  position: relative;
}

.input input {
  outline: none;
}

.validation #title {
  color: var(--dark-red);
  margin: 0;
  font-size: 14px;
}

.validation .err-list {
  margin: 0;
  font-size: 12px;
}

.validation .err-msg {
  color: var(--dark-red);
  margin: 0;
}

.input i {
  position: absolute;
  right: -5px;
  top: 25px;
}