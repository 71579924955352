#signup-container {
    height: 100%;
    display: grid;
    font-family: 'Montserrat', sans-serif;
    padding: 0 20px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 750px) {
    #signup-container {
        grid-template-columns: 1fr;
    }
}

