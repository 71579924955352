#messages {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 100px;
}

#messages #conversation-header {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
}

#messages #conversation-header p {
    margin: none;
    margin: 0;
    margin-top: 1rem;
    font-size: 30px;
    display: inline-block;
    align-self: flex-end;
}

#messages #conversation-header i {
    position: absolute;
    left: 3rem;
    top: 2rem;
    font-size: 30px;
    color: gray;

    cursor: pointer;
    pointer-events: fill;
}

#messages-container {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;

    overflow-y: scroll;
    overflow-x: hidden;

    background-color: white;

    padding: 1rem;
    margin: 2rem 1rem;
    border-radius: 10px;
    box-shadow: 1px 1px 3px black;
}