body {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

#root {
    width: 100%;
    height: 100%;
    /* display: flex;
    flex-direction: column; */

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;

    overflow-y: hidden;
    overflow-x: hidden;
    background-color: var(--main-bg-color);
}

::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
    padding-left: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb {
    background-color: var(--accent-one);
    border-radius: 5px;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-scrollbar-button {
    display: none;
}

:root {
    --dark-red: #d80f24;
    --main-bg-color: rgba(138, 43, 226, 0.05);
    --main-bg-color-solid: #f9f4fe;
    --accent-one: #f25787;
    --accent-two: #f2195c;
    /* blueviolet; */
}

@media (max-width: 700px) {
    /* #root {
        display: flex;
        flex-direction: column;
    } */
    #root {
        grid-template-rows: 1fr 70px;
    }
}
