#profile-page {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: minmax(400px, 1fr) 1fr 1fr;

  position: relative;

  overflow: hidden;

  width: 100%;
  height: 100%;

  transition: transform 0.1s ease;
}

#profile-page[draggable='true'] {
  cursor: grab;
}

#profile-page #photos {
  grid-row: 1 / -1;
  grid-column: 2 / 3;

  height: 100%;

  box-sizing: border-box;

  overflow-y: scroll;

  display: grid;
  grid-auto-rows: 33vw;

  scroll-snap-type: y mandatory;
  padding: 25px 0;
  scroll-margin-top: 25px;
}

#profile-page.dislikes {
  animation: 1s ease dislike
}

#profile-page.likes {
  animation: 1s ease like
}

@keyframes dislike {
  to {
    transform: translate(-300%, -300%) rotate(-45deg);
  }
}

@keyframes like {
  to {
    transform: translate(300%, -300%) rotate(45deg);
  }
}

#navbar {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

@media (max-width: 1120px) {
  #profile-page {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 50px 20px;
    padding-bottom: 100px;
    box-sizing: border-box;
    gap: 50px;
    -webkit-overflow-scrolling: touch;
  }

  #root {
    overflow: visible;
  }
}
