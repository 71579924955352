#profile-loading {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

#profile-loading i {
  animation: 2s linear infinite spin;
  font-size: 100px;
  color: lightgray;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}