#disclaimer-page {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 2 / -1;
  height: 100%;
}


#disclaimer-page #main-body {
  height: 95%;
  max-width: 800px;
  background-color: white;
  margin: 0 25px;

  box-sizing: border-box;
  padding: 2rem 4rem;
  border-radius: 20px;
  box-shadow: 0 0 10px black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-y: scroll;
}

#main-body > * {
  margin: 0;
}

#main-body h1 {
  font-size: 4vmin;
}

#main-body > p {
  font-size: 2.75vmin;
  line-height: 1.6em;
  letter-spacing: 0.1em;
  color: gray;
  font-weight: 500;
}

#signature {
  position: relative;
}

#signature > p {
  /* text-align: end; */
  margin: 0;
  font-size: 3vmin;
  font-weight: bold;
}

#signature p:nth-of-type(2) {
  display: inline-block;
}

#disclaimer-page a {
  font-size: 3vmin;
  color: var(--accent-one);
  margin-left: 0 auto;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 650px) {
  #disclaimer-page {
    grid-row: 1 / 2;
  }
  #disclaimer-page #main-body {
    padding: 1rem 2rem;
  }
  #main-body h1 {
    font-size: 24px;
  }
}