.conversation-link {
    width: 90%;
    padding: 20px;
    height: 50px;


    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 1px 8px gray;
    cursor: pointer;
    display: flex;
    gap: 10px;
    position: relative;
}

.conversation-link .conversation-info {
    flex: 1;
}

.conversation-info .detail {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.conversation-info .detail p {
    position: absolute;
    justify-self: flex-start;
    left: 0px;
    color: gray;
    margin-left: 5px;
    font-size: 14px;
}

.conversation-link p {
    display: block;
    margin: 0;
    font-size: 20px;
}

.conversation-link i {
    color: gray;
}

