#signup-form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}

#signup-form form {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 80%;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 100%;
    position: relative;
}

#signup-form .page {
    height: 100%;
    width: 100%;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    transition: top ease .75s;
}

#signup-form .page .input {
    width: 60%;
}

#signup-form .page .input input {
    font-size: 16px;
    padding-inline: 10px;
    padding-block: 5px;
    border: 1px solid gray;
    border-radius: 50px;
    width: 100%;  
}

#signup-form .page .input label {
    font-size: 14px;
    color: gray;
    position: relative;
    left: -10px;
    top: -5px;
}

#signup-form section > .title {
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 48px;
    justify-self: start;
}

#signup-form section > .subtitle {
    font-size: 16px;
    text-align: center;
    justify-self: start;
    color: gray;
    margin: 0;
}

#signup-form #birthday {
    font-family: 'Montserrat', sans-serif;
    color: gray;
}

#signup-form .select select {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    border-radius: 50px;
    padding-inline: 5px;
    padding-block: 5px;
}

#signup-form #button-bar {
    width: 100%;
    display: flex;
    justify-content: center;
}

#button-bar i.loader {
    font-size: 25px;
    animation: 2s linear infinite spin;
}

#signup-form #signup-button {
    width: fit-content;
    font-size: 18px;
    font-family: 'Monserrat', sans-serif;
    background-color: var(--accent-one);
    border: none;
    border-radius: 50px;
    color: white;
    font-weight: 600;
    padding: 15px 20px;
}

#signup-form #err-msg {
    text-align: center;
    border: 1px solid var(--dark-red);
    color: var(--dark-red);
    border-radius: 10px;
    width: 80%;
    padding: 20px 0px;
    margin: 2rem auto;
}

#signup-form #login-prompt {
    text-align: center;
    margin: 1rem 0;
    color: gray;
}

#login-prompt a {
    color: var(--accent-two)
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}
