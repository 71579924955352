#button-bar {
  display: flex;
  justify-content: space-between;
}

#like, #dislike {
  height: fit-content;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 20px;
}

#button-bar #like , #button-bar #dislike {
  font-size: 20px;
}

#dislike {
  border: 1px solid var(--dark-red);
  color: var(--dark-red)
}

#like {
  border: 1px solid var(--accent-one);
  color: var(--accent-one);
}

@media (max-width: 1120px) {
  #button-bar {
    order: 10;
  }
}