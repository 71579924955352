.hate {
  border-radius: 50px;
  
  padding: 15px;
  width: fit-content;
  height: fit-content;

  justify-self: center;

  position: relative;

  color: white;
  background-color: var(--accent-two);
  z-index: 11;
}

.hate i {
  padding-left: 0.5rem;
  color: black;
}
