.photo-input {
  position: relative;
  height: 90%;
  aspect-ratio: 4 / 5;
  box-sizing: border-box;

  border-radius: 10px;
  box-shadow: 0px 0px 10px gray;

  outline: none;

  justify-self: center;
  align-self: center;

  scroll-snap-align: center;
  scroll-snap-stop: always;

  transition: height 0.5s ease, width 0.5s ease, outline 0.25s ease, box-shadow 0.5s ease;

  background-color: white;
}

.photo-input:hover {
  outline: 2px solid var(--accent-one);
  box-shadow: 0px 0px 20px black;
}


.photo-input img {
  width: 100%;
  aspect-ratio: 4 / 5;

  object-fit: cover;
  object-position: center;
  border-radius: 10px;

  background-color: white;

  position: absolute;

  z-index: 5;
}

.photo-input button {
  z-index: 2;
  position: absolute;

  top: 105%;
}

.photo-input .delete-btn {
  height: 30px;
  width: 30px;
  border-radius: 999%;

  background-color: var(--dark-red);

  position: absolute;
  top: -12px;
  right: -12px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
}

.photo-input .delete-btn i {
  color: white;
}

.icon-container {
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  border-radius: 10px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
}

.icon-container i {
  color: lightgray;
}

.photo-input input[type="file"] {
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
  color: white;
  
  scroll-snap-align: center;
}

.photo-input input[type='file'].hasImage {
  pointer-events: none;
}

.photo-input label {
  position: absolute;
  top: -30px;
  left: -5px;
  font-size: 18px;
}

@media (max-width:1120px) {
  .photo-input {
    aspect-ratio: 4 / 5;
    min-height: 400px;
  }
  
}